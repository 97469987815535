.routines_container_menu {
  padding-top: 20px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: rgba(22, 22, 22,1);
  border-bottom-right-radius: 60px;

  background-repeat: no-repeat;
  background-position: left top;
  background-size: 150px;
  background-image: url("../../assets/bg_dec.png");
}

.routines_subcontainer_menu {
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.routines_container_title {
  padding-top: 50px;
  width: 100%;
  max-width: 1000px;
  height: 100%;
  margin: 0 auto;
}


.routines_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 100px;
}