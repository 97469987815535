:root {
  font-size: 10px;
  /* Viariables */
  --salsafitt_grayDark: #222323;
  --salsafitt_orange: #f18823;
  --salsafitt_orange_hover: #cc6c13;
  --salsafitt_green: #82bc43;
  --salsafitt_green_hover: #6fa338;
  --white_bone: rgb(247, 247, 247);
}

::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.6rem;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 1.5rem;
  background-color: #dfdfdf;
}

body::-webkit-scrollbar-thumb {
  border: 0.35rem solid #dfdfdf;
  border-radius: 0.7rem;
  background-color: var(--salsafitt_grayDark);
}

.body {
  /* overflow: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.paddingTop_container {
  padding-top: 60px;
}

.white_bone {
  background-color: rgb(247, 247, 247);
}


/* Media Screens */

@media screen and (max-width: 700px) {
  .paddingTop_container {
    padding-top: 6rem;
  }
}