
.rec.rec-dot {
  display: none;
}

.rec.rec-arrow {
  background: transparent;
  box-shadow: 0 0 0 0 ;
}
.rec.rec-arrow:hover {
  box-shadow: 0 0 0 0!important;;
  background-color: rgba(0, 0, 0, 0.1)!important;
}
.rec.rec-arrow:focus {
  box-shadow: 0 0 0 0!important;;
  background-color: rgba(0, 0, 0, 0.1)!important;
}

.carousel_container {
  /* background-color:brown; */
}