.header h3 {
  margin: 0;
  padding: 0;
}

.header {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 60px;
  margin: 0;
  /* padding: 0 2rem; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  color: #ffffff;
  /* background-image: -o-linear-gradient(top, rgba(211, 11, 11, 0.75), transparent);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.75)),
    to(transparent)
  ); */
  /* background-image: -o-linear-gradient(top, rgba(29, 199, 23, 0.75), transparent); */
  /* background-image: #000; */
  background-color: rgba(0, 0, 0, 1);
  -webkit-transition: height ease 0.6s;
  -o-transition: height ease 0.6s;
  -webkit-transition: background-image ease 0.6s, height ease 0.6s;
  -o-transition: background-image ease 0.6s, height ease 0.6s;
  transition: background-image ease 0.6s, height ease 0.6s;
}

.header-bg {
  height: 70px;
  background-image: -o-linear-gradient(
    top,
    var(--salsafitt_grayDark),
    var(--salsafitt_grayDark)
  );
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(var(--salsafitt_grayDark)),
    to(var(--salsafitt_grayDark))
  );
  background-image: linear-gradient(
    to bottom,
    var(--salsafitt_grayDark),
    var(--salsafitt_grayDark)
  );
}

.header_logo_salsafitt {
  width: 5rem;
  margin: 1.5rem;
  margin-left: 30px;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
  -webkit-transition: width ease 0.6s, margin ease 0.6s;
  -o-transition: width ease 0.6s, margin ease 0.6s;
  transition: width ease 0.6s, margin ease 0.6s;
}

.header-bg .header_logo_salsafitt {
  /* width: 5rem; */
  /* margin: 0.5rem 2rem; */
}



.header_h3_salsafitt {
  font-size: 2.2rem;
  /* text-transform: uppercase; */
  color: var(--salsafitt_orange);
  -webkit-transition: font-size ease 0.6s;
  -o-transition: font-size ease 0.6s;
  transition: font-size ease 0.6s;
}

.header-bg .header_h3_salsafitt {
  font-size: initial;
}


.header_link_home {
  width: 10%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}

.header_info_container {
  width: 100%;
  height: 100%;
}

.header_infoLinks_subcontainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: brown; */
}

.header_login_container {
  width: 10%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* background-color: rgb(139, 32, 0); */
}



.header_buttonMenu_mobile {
  display: none;
}

.header_line_hover {
  -webkit-transition: background-color ease 0.4s;
  -o-transition: background-color ease 0.4s;
  transition: background-color ease 0.4s;
}

.header_infoLinks_container_active::-webkit-scrollbar {
  width: 0.9rem;
}

.header_infoLinks_container_active::-webkit-scrollbar-thumb {
  border-top-left-radius: 0.7rem;
  border-bottom-left-radius: 0.7rem;
  background-color: #22232360;
}

.header_infoLinks_container {
  display: block;
  position: initial;
  width: 100%;
  height: 100%;
}



.header_infoLinks_link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  margin: 0 20px;
  -webkit-transition: color ease 0.4s;
  -o-transition: color ease 0.4s;
  transition: color ease 0.4s;
}

.header_infoLinks_link:hover {
  color: var(--salsafitt_green);
}

.header_infoLinks_link_active {
  color: var(--salsafitt_green);
}

.header_infoLinks_container_active {
  display: block;
  position: fixed;
  top: 60px;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  -webkit-animation: actionMenuMobile 0.3s ease both;
  animation: actionMenuMobile 0.3s ease both;
  color: #ffffff;
  background-color: var(--salsafitt_grayDark);
}

.header_menuCloseSecondary {
  position: absolute;
  top: 0;
  right: 0;
  width: 5rem;
  height: 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--salsafitt_grayDark);
}

.buttonSecondary_disabled {
  display: none;
}

.header_menuCloseSecondary_icon {
  color: var(--salsafitt_orange);
}

.header_buttons_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 14rem;
  height: 100%;
  margin: 0 4rem;
}

.header_buttons_login,
.header_buttons_shop,
.header_buttons_language {
  width: 50%;
  /* min-width: 5rem; */
  height: 100%;
  margin: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  -webkit-transition: color ease 0.4s;
  -o-transition: color ease 0.4s;
  transition: color ease 0.4s;
}

.header_buttons_icon {
  color: #ffffff;
  -webkit-transition: color ease 0.4s;
  -o-transition: color ease 0.4s;
  transition: color ease 0.4s;
}

.header_buttons_icon_active {
  color: var(--salsafitt_green);
}

.header_buttons_login:hover .header_buttons_icon,
.header_buttons_shop:hover .header_buttons_icon,
.header_buttons_language:hover,
.header_buttons_language:hover .header_buttons_icon {
  color: rgb(170, 170, 170);
}

.header_language_container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.header_language_container_active {
  display: block;
}

.header_language_subcontainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 15rem;
  height: 10rem;
  border-radius: 0.5rem;
  /* border: thin solid var(--salsafitt_grayDark); */
  overflow: hidden;
  /* -webkit-box-shadow: 0 0 0.5rem 0.05rem var(--salsafitt_grayDark);*/
  box-shadow: 0 0 10px .5px rgba(0, 0, 0, 0.1); 
  background-color: #ffffff;
}

.header_lang {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;
  -webkit-transition: background-color ease 0.2s;
  -o-transition: background-color ease 0.2s;
  transition: background-color ease 0.2s;
}

.header_lang:hover {
  background-color: rgb(230, 230, 230);
}

.header_lang p {
  margin: 0;
  padding: 0;
  color: var(--salsafitt_grayDark);
}

/* Media Screens */

@media screen and (max-width: 600px) {
  .header-bg {
    height: 6.4rem;
  }
  .header_logo_salsafitt {
    margin: 0.5rem 2rem;
  }
  .header_section_salsafitt {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  @-webkit-keyframes actionMenuMobile {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes actionMenuMobile {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  .header {
    padding: 0;
  }
  .header_buttonMenu_mobile {
    width: 7rem;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    cursor: pointer;
  }
  .header_line_top,
  .header_line_middle,
  .header_line_bottom {
    width: 2rem;
    height: 0.2rem;
    margin: 0.3rem 2rem;
    border-radius: 2rem;
    background-color: #ffffff;
    -webkit-transition: width ease 0.6s, display ease 0.6s,
      background-color ease 0.6s, -webkit-transform ease 0.6s;
    transition: width ease 0.6s, display ease 0.6s, background-color ease 0.6s,
      -webkit-transform ease 0.6s;
    -o-transition: transform ease 0.6s, width ease 0.6s, display ease 0.6s,
      background-color ease 0.6s;
    transition: width ease 0.6s, display ease 0.6s, background-color ease 0.6s,
      -webkit-transform ease 0.6s;
    transition: transform ease 0.6s, width ease 0.6s, display ease 0.6s,
      background-color ease 0.6s;
    transition: transform ease 0.6s, width ease 0.6s, display ease 0.6s,
      background-color ease 0.6s, -webkit-transform ease 0.6s;
  }
  .header_line_top {
    width: 3rem;
  }
  .header_line_top_close,
  .header_line_middle_close,
  .header_line_bottom_close {
    background-color: var(--salsafitt_orange);
  }
  .header_line_top_close {
    -webkit-transform: translateY(0.4rem) rotate(-45deg);
    -ms-transform: translateY(0.4rem) rotate(-45deg);
    transform: translateY(0.4rem) rotate(-45deg);
  }
  .header_line_middle_close {
    display: none;
  }
  .header_line_bottom_close {
    width: 3rem;
    -webkit-transform: translateY(-0.4rem) rotate(45deg);
    -ms-transform: translateY(-0.4rem) rotate(45deg);
    transform: translateY(-0.4rem) rotate(45deg);
  }
  .header_infoLinks_container_disabled {
    display: none;
  }
  .header_menuCloseSecondary {
    display: none;
  }
  .header_link_home {
    width: 50%;
  }
  .header_info_container {
    width: 50%;
  }
  .header_infoLinks_subcontainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .header_infoLinks_link {
    width: 60%;
  }
  .header_buttons_container {
    margin-right: 0;
  }
}
