.wpp_dialog {
  position: fixed;
  -webkit-transition: background-color ease 0.4s;
  -o-transition: background-color ease 0.4s;
  transition: background-color ease 0.4s;
}

.wpp_dialog_active {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3);
}

.wpp_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 110;
  width: 3.5rem;
  height: 18rem;
  cursor: pointer;
  border-top: thin solid white;
  border-left: thin solid white;
  border-top-left-radius: 1rem;
  -webkit-transition: width ease 0.4s, height ease 0.4s;
  -o-transition: width ease 0.4s, height ease 0.4s;
  transition: width ease 0.4s, height ease 0.4s;
  -webkit-box-shadow: 0 0 1rem 0.15rem rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 1rem 0.15rem rgba(0, 0, 0, 0.3);
  background-color: #128c7e;
}

.wpp_dialogClose {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: aquamarine;
}

.wpp_iconText_container {
  position: relative;
  z-index: 20;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 85%;
  height: 95%;
}

.wpp_iconText_text {
  margin: 0;
  padding: 0;
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  color: #ffffff;
}

.wpp_iconText_icon {
  margin: 2rem 0 0;
  color: #ffffff;
}

.wpp_info_container {
  display: none;
  width: 850%;
  height: 95%;
  border-radius: 1rem;
  border-right: thick solid #128c7e;
  background-color: #ffffff;
}

.wpp_container_dialogActive {
  width: 35rem;
  max-width: 85%;
  height: 70vh;
  min-height: 45rem;
  max-height: 80vh;
}

.wpp_container_dialogActive .wpp_info_container {
  display: block;
}

.wpp_info_headerName_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 95%;
  height: 10%;
  margin: 0 auto;
  border-bottom: thin solid #128c7e;
}

.wpp_info_headerName_container h4 {
  margin: 0 1rem 0 0;
  padding: 0;
}

.wpp_form_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 95%;
  height: 70%;
  margin: 0 auto;
}

.wpp_form_container label {
  width: 95%;
  margin: 1rem 0 0;
  font-size: 1.4rem;
}

.wpp_form_container input {
  width: 95%;
  height: 2.5rem;
  margin: 0.25rem;
}

.wpp_info_preloadText_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 95%;
  max-width: 25rem;
  height: auto;
  margin: 0.5rem auto;
  padding: 0.5rem 0;
  border-top: thin solid #128c7e;
}

.wpp_info_preloadText_container h6 {
  margin: 0;
  padding: 0;
}

.wpp_info_preloadText_container p {
  margin: 0.25rem;
  padding: 0.5rem 1rem;
  text-align: justify;
  border-radius: 1rem;
  border: medium solid transparent;
  cursor: pointer;
  -webkit-transition: border ease 0.4s;
  -o-transition: border ease 0.4s;
  transition: border ease 0.4s;
  -webkit-box-shadow: 0 0 0.25rem 0.25rem#e0fcd460;
  box-shadow: 0 0 0.25rem 0.25rem#e0fcd460;
  background-color: #e0fcd4;
}

.wpp_info_preloadText_container_selected p {
  border: medium solid #128c7e;
}

.wpp_info_preloadText_container textarea {
  width: 100%;
  resize: none;
  padding: 0.5rem;
  font-size: 1.4rem;
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 0.01rem;
  border-radius: 1rem;
  -webkit-box-shadow: 0 0 0.25rem 0.25rem#e0fcd460;
  box-shadow: 0 0 0.25rem 0.25rem#e0fcd460;
  background-color: #e0fcd4;
}

.wpp_info_preloadText_container textarea::-webkit-scrollbar {
  width: 1rem;
  background-color: #dfdfdf;
}

.wpp_info_preloadText_container textarea::-webkit-scrollbar-thumb {
  border: 0.35rem solid #dfdfdf;
  border-radius: 0.7rem;
  background-color: var(--salsafitt_grayDark);
}

.wpp_send_container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 10%;
  margin: 0.5rem 0;
}

.wpp_send_btn_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 50%;
  height: 95%;
  border-radius: 0.5rem;
  text-decoration: none;
  cursor: pointer;
  background-color: #128c7e;
}

.wpp_send_btn_container h6 {
  margin: 0 2rem;
  padding: 0;
  font-size: 1.6rem;
  color: #ffffff;
}

.wpp_send_btn_icon {
  color: #ffffff;
}

.wpp_send_btn_container_disabled {
  border: thin solid #128c7e;
  color: #bebebe;
  background-color: rgba(0, 0, 0, 0.1);
}

.wpp_send_btn_container_disabled h6 {
  margin: 0 2rem;
  padding: 0;
  font-size: 1.6rem;
  color: #bebebe;
}

.wpp_send_btn_container_disabled .wpp_send_btn_icon {
  color: #bebebe;
}

.wpp_send_btn_container_divdisabled {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.wpp_send_btn_container_divdisabled_no {
  display: none;
}
