.home_sectionMedia_container {
  width: 100%;
  height: 100vh;
}

.home_sectionMedia_div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #000;
  
}

.home_sectionMedia_media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}


.home_banner_title {
  margin: 0;
  font-size: 50px;
  text-align: center;
}

.home_banner_subtitle {
  margin: 0;
  text-align: center;
  padding: 20px;
  margin-bottom: 20px;
}

.home_banner_btns_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .home_sectionMedia_container,
  .home_sectionMedia_div {
    height: 400px;
  }
  .home_banner_title {
    font-size: 40px;
  }

  .home_banner_btns_container {
    display: block;
    margin: 0 auto;
  }
}

@media screen and (max-width: 600px) {
  .home_sectionMedia_container,
  .home_sectionMedia_div {
    height: 240px;
  }
}




.home_logo_img {
  margin-top: 50px;
  width: 160px;
  height: 100%;
  object-fit: contain;
  object-position: center;
}


.home_principal_info_container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 20%;
  height: 100%;
  margin: 3rem 5rem;
}

.home_principal_info_container h2,
.home_principal_info_container p,
.home_principal_info_container h3 {
  margin: 0;
  padding: 0;
  text-align: end;
}

.home_principal_info_container h2 {
  font-size: 45px;
  color: var(--salsafitt_green);
}

.home_principal_info_container h3 {
  font-size: 20px;
  color: var(--salsafitt_green);
}

@media screen and (max-width: 700px) {
  .home_principal_info_container {
    align-items: center;
    width: 90%;
  }
  .home_principal_info_container h2,
  .home_principal_info_container p,
  .home_principal_info_container h3 {
    text-align: center;
  }
  .harness_principal_img_container {
    width: 100%;
  }  
}






.home_banner_getHarnessBtn_container,
.home_banner_getAppBtn_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 45%;
  text-decoration: none;
  font-weight: bold;
  border-radius: 0.5rem;
  border-width: medium;
  border-style: solid;
  -webkit-transition: background-color ease 0.4s, color ease 0.4s,
    border-color ease 0.4s;
  -o-transition: background-color ease 0.4s, color ease 0.4s,
    border-color ease 0.4s;
  transition: background-color ease 0.4s, color ease 0.4s,
    border-color ease 0.4s;
  color: inherit;
}

.home_banner_getHarnessBtn_container {
  border-color: var(--salsafitt_orange);
  background-color: var(--salsafitt_orange);
}

.home_banner_getHarnessBtn_container:hover {
  border-color: var(--salsafitt_orange_hover);
  background-color: var(--salsafitt_orange_hover);
}

.home_banner_getAppBtn_container {
  border-color: #ffffff;
}

.home_banner_getAppBtn_container:hover {
  color: #000000;
  background-color: #ffffff;
}

.home_banner_getAppBtn_text,
.home_banner_getHarnessBtn_text {
  letter-spacing: 0.1rem;
  font-size: 1.8rem;
}

@media screen and (max-width: 900px) {
  .home_banner_getHarnessBtn_container,
  .home_banner_getAppBtn_container {
    width: 100%;
    margin-bottom: 20px;
  }
}




.home_harnessInfo_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  padding: 6.5rem 0;
  background-color: var(--white_bone);
}

.home_title_h2,
.home_title_p {
  margin: 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.home_title_h2 {
  font-size: 2.5rem;
  color: var(--salsafitt_green);
}

.home_harnessInfo_subcontainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.home_characteristics_container {
  width: 25rem;
  height: 40rem;
  margin: 2rem;
}

.home_characteristics_img_container {
  width: 100%;
  height: 70%;
  border-radius: 1.5rem;
  overflow: hidden;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
}

.home_characteristics_img_img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.home_characteristics_info_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 30%;
}

.home_characteristics_info_container h3 {
  margin: 1rem;
  padding: 0;
  text-transform: uppercase;
}

.home_characteristics_info_container p {
  margin: 0;
  padding: 0;
  color: #00000060;
}

/* Media Screens */








.compra_maincontainer {
  width: 100%;
  background-color: var(--salsafitt_grayDark);
  background-repeat: no-repeat;
  background-position: left top, right bottom;
  background-size: 30rem;
  background-image: url("../../assets/bg_dec.png"), url("../../assets/bg_dec_rev.png");
}

.compra_sectionMedia_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #222323;
}

.compra_sectionMedia_div_dark {
  margin-top: 150px;
  margin-bottom: 150px;
  color: #ffffff;
}


@media screen and (max-width: 900px) {
  
  .compra_sectionMedia_div_dark {
    width: 80%;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .compra_sectionMedia_container {
    height: 100vh;
  }
}




.home_cards_subcontainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 30px;
}








.home_routines {
  width: 100%;
  /* min-height: 45rem; */
  padding: 100px 0;
  background-color: var(--white_bone);
}

.home_routines_container {
  display: flex;
  /* justify-content: center; */
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 1000px;
  height: 100%;
  margin: 0 auto;
  padding-top: 20px;
  background-color: aquamarine;
}




.coaches_header_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url("http://strengthfitness.fit/images/salsafitt-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  padding: 150px 0;
}
.coaches_header_container_text {
  padding: 0 20px;
}

.coaches_header_container h2,
.coaches_header_container h4 {
  margin: 0;
  padding: 0;
  color: #ffffff;
}

.coaches_headerHome_link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  width: 15rem;
  height: 5rem;
  margin: 5rem 0 0;
  border-radius: 1rem;
  border: thin solid var(--salsafitt_orange);
  -webkit-transition: background-color ease 0.4s, color ease 0.4s;
  -o-transition: background-color ease 0.4s, color ease 0.4s;
  transition: background-color ease 0.4s, color ease 0.4s;
  color: var(--salsafitt_orange);
}

.coaches_headerHome_link:hover {
  color: #ffffff;
  background-color: var(--salsafitt_orange);
}

@media screen and (max-width: 900px) {
  .coaches_header_container {
    padding: 0 0;
    height: 100vh;
  }
}