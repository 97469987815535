.account_maincontainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: var(--salsafitt_grayDark);
  height: auto;
  background-repeat: no-repeat;
  background-position: left top, right bottom;
  background-size: 30rem;
  background-image: url("../../assets/bg_dec.png"), url("../../assets/bg_dec_rev.png");
}

@media screen and (max-width: 900px) {
  .account_maincontainer {
    background-size: 100px;
  }
}

.account_link_home {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
  margin: 2rem 0 0;
}

.account_logo_salsafitt {
  width: 100px;
  margin-top: 20px;
  margin-right: 1rem;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

.account_log_padding {
  margin-top: 100px;
}



.account_titile_container {
  margin-bottom: 40px;
}

.account_titile_container h3 {
  font-size: 20px;
  font-weight: normal;
  color: #ffffff;
  text-align: center;
}
.account_titile_container p {
  font-size: 14px;
  font-weight: normal;
  color: #ffffff;
  text-align: center;
}

.account_form_container {
  width: 400px;
}

@media screen and (max-width: 900px) {
  .account_form_container {
    width: 90%;
  }
}

.account_form {
  padding: 0;
  border: 0;
  width: 100%;
  background-color: rgb(9, 68, 95);
}


.account_form_forgot {
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  cursor: pointer;
  color: #bebebe;
  text-align: center;
}

.account_form_terminos {
  margin: 0;
  padding: 0;
  margin-top: 40px;
  font-size: 14px;
  cursor: pointer;
  color: #bebebe;
  text-align: center;
  text-decoration: underline rgb(102, 102, 102); 
}
.account_form_terminos a{
  margin: 0;
  padding: 0;
  margin-top: 40px;
  font-size: 14px;
  cursor: pointer;
  color: #bebebe;
  text-align: center;
  text-decoration: underline rgb(102, 102, 102); 
}

.account_form_error {
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  color: #cc2a2a;
  text-align: center;
}

.account_form_submitBtn {
  width: 20rem;
  height: 5rem;
  border: none;
  margin: 3rem 0;
  border-radius: 10rem;
  font-size: 1.7rem;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
  background-color: var(--salsafitt_green);
}

.account_form_submitBtn_disabled {
  cursor: not-allowed;
  color: #bebebe;
  background-color: rgba(255, 255, 255, 0.1);
}

.account_form_showHide {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* margin: 0 2rem 0 auto; */
  cursor: pointer;
}

.account_form_showHide_icon {
  color: #bebebe;
}

.account_noAccount {
  width: 100%;
  margin-top: 30px;
}

.link_noline {
  text-decoration: none;
}

.account_noAccount p{
  width: 100%;
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  color: #bebebe;
}

.account_noAccount b {
  margin-top: 50px;
  text-decoration: none;
  color: var(--salsafitt_green);
}


.account_input_container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 0.5rem;
  padding: 0 1.5rem;
  background-color: rgba(255, 255, 255, 0.1);
}

.account_input {
  width: 80%;
  height: 5.6rem;
  padding: 0 1.5rem;
  border: none;
  font-size: 1.6rem;
  color: #ffffff;
  background-color: transparent;
  text-decoration: none;
  outline: none;
}

.account_line{
  margin: 30px 0px;
  border-top: 1px solid rgba(255, 255, 255, 0.24)
}


.account_btns_container {
  width: 100%;
  margin: 40px 0;
}

.account_btn_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  -webkit-transition: background-color ease 0.4s, color ease 0.4s, border-color ease 0.4s;
  -o-transition: background-color ease 0.4s, color ease 0.4s, border-color ease 0.4s;
  transition: background-color ease 0.4s, color ease 0.4s, border-color ease 0.4s;
}

.account_btn_container p {
  font-size: 1.6rem;
  font-weight: bold;
  color: #ffffff;
}


.account_btn_color_1 {
  background-color: var(--salsafitt_green);
}
.account_btn_color_1:hover {
  background-color: var(--salsafitt_green_hover);
}

.account_btn_color_2 {
  background-color: #4267B2;
}
.account_btn_color_2:hover {
  background-color: #355caa;
}