.routine_inframe_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000000;
}

.routine_inframe_subcontainer {
  width: 100%;
  height: 600px;
  max-width: 1200px;
  margin: 10px;
}

.routine_videotrailer {
  width: 100%;
  height: 100%;
}

.routine_main {
  width: 100%;
  margin: 50px auto;
  max-width: 1000px;
}

.routine_info_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.routine_info_subcontainer {
  width: 100%;
  /* background-color: cornflowerblue; */
}

.routine_info_container p,
.routine_info_container h2,
.routine_info_container h3 {
  margin: 1rem;
  padding: 0;
}

.routine_info_subcontainer h2 {
  font-size: 4rem;
  text-transform: uppercase;
  text-align: center;
  color: var(--salsafitt_orange);
}

.routine_info_subcontainer h3 {
  color: var(--salsafitt_green);
}

.routine_info_container p {
  font-size: 1.8rem;
  text-align: justify;
}

.routine_coach_container,
.routine_tutorial_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin: 2rem 0;

  /* background-color: rgb(73, 26, 36); */
}

.routine_coach_container h2 {
  text-align: center;
  color: var(--salsafitt_green);
}

.routine_coach_img_container {
  width: 80%;
  height: 30rem;
}

.routine_coach_img_img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

.routine_tutorial_container p {
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
}

.routine_tutorial_btn_container,
.routine_start_btn_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 15rem;
  height: 5rem;
  margin: 2rem 0;
  border-radius: 0.5rem;
  border-width: medium;
  border-style: solid;
  cursor: pointer;
  -webkit-transition: background-color ease 0.4s, color ease 0.4s,
    border-color ease 0.4s;
  -o-transition: background-color ease 0.4s, color ease 0.4s,
    border-color ease 0.4s;
  transition: background-color ease 0.4s, color ease 0.4s,
    border-color ease 0.4s;
}

.routine_tutorial_btn_container {
  border-color: var(--salsafitt_orange);
  color: var(--salsafitt_orange);
}

.routine_tutorial_btn_container:hover {
  color: #ffffff;
  background-color: var(--salsafitt_orange);
}

.routine_moreInfo_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 25rem;
  height: 7rem;
  margin: 1.5rem 0;
}

.routine_moreInfo_icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 25%;
  height: 100%;
  color: var(--salsafitt_orange);
}

.routine_moreInfo_info {
  width: 75%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-weight: bold;
}

.routine_moreInfo_info h3 {
  font-size: 2rem;
  color: var(--salsafitt_green);
}

.routine_moreInfo_info p {
  font-size: 1.6rem;
}

.routine_lineColor {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 2rem 0;
  border-top: thin solid var(--salsafitt_orange);
  border-bottom: thin solid var(--salsafitt_orange);
}

.routine_start_btn_container {
  border-color: var(--salsafitt_green);
  background-color: var(--salsafitt_green);
}

.routine_start_btn_container p {
  font-size: 1.6rem;
  font-weight: bold;
  color: #ffffff;
}

.routine_start_btn_container:hover {
  border-color: var(--salsafitt_green_hover);
  color: #ffffff;
  background-color: var(--salsafitt_green_hover);
}

/* Media Screens */

@media screen and (max-width: 900px) {
  .routine_inframe_subcontainer {
    width: 100%;
    height: 240px;
  }
  .routine_info_container,
  .routine_tutorial_container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .routine_main {
    margin: 30px auto;
  }
}
