.music_modal_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
}

.music_modal_close {
  z-index: 1;
}

.music_modal_video_container {
  position: relative;
  width: 800px;
  height: 450px;
  max-width: 90%;
  z-index: 2;
  -webkit-box-shadow: 0 0 3rem 1rem rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 3rem 1rem rgba(0, 0, 0, 0.5);
  background-color: #000000;
}

.music_modal_title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  width: 60rem;
  max-width: 90%;
  z-index: 2;
  margin: 0;
  font-size: 2rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-bottom: thin solid var(--salsafitt_orange);
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.25);
}

.music_modal_img_container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.music_modal_img_img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

@media screen and (max-width: 900px) {
  .music_modal_video_container {
    width: 800px;
    height: 250px;
  }
}

