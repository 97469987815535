.music_card_continainer {
  width: 280px;
  height: 270px;
  margin: 10px 20px;
  overflow: hidden;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2);
}

.music_card_img_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 160px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.1);
}

.music_card_img_img {
  width: 100%;
  height: 100%;
  /* -o-object-fit: contain; */
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  -webkit-transition: width ease 0.7s, height ease 0.7s;
  -o-transition: width ease 0.7s, height ease 0.7s;
  transition: width ease 0.7s, height ease 0.7s;
}

.music_card_continainer:hover .music_card_img_img {
  width: 105%;
  height: 105%;
}


.music_card_info_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  min-height: 10rem;
}

.music_card_info_title_container {
  width: 65%;
  height: 100%;
}

.music_card_info_title_container h3,
.music_card_info_title_container h5 {
  margin: 1rem 1.5rem;
  padding: 0;
}

.music_card_info_title_container h3 {
  font-size: 2.3rem;
  color: var(--salsafitt_orange);
}

.music_card_info_btn_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 35%;
  height: 100%;
}

.music_card_info_btn_btn {
  width: 60%;
  height: 6rem;
  cursor: pointer;
  border-radius: 1rem;
  border: thin solid var(--salsafitt_orange);
  -webkit-transition: background-color ease 0.4s;
  -o-transition: background-color ease 0.4s;
  transition: background-color ease 0.4s;
  background-color: transparent;
}

.music_card_info_btn_icon {
  -webkit-transition: color ease 0.4s;
  -o-transition: color ease 0.4s;
  transition: color ease 0.4s;
  color: var(--salsafitt_orange);
}

.music_card_info_btn_btn:hover {
  background-color: var(--salsafitt_orange);
}

.music_card_info_btn_btn:hover .music_card_info_btn_icon {
  color: #ffffff;
}
