.card_routines_subcontainer {
  width: 200px;
  height: 250px;
  margin:20px;
  border-radius:5px;
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
  /* background-color: rgb(49, 49, 49); */
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);

  -webkit-transition: -webkit-box-shadow ease 0.4s, -webkit-transform ease 0.4s;
  transition: -webkit-box-shadow ease 0.4s, -webkit-transform ease 0.4s;
  -o-transition: box-shadow ease 0.4s, transform ease 0.4s;
  transition: box-shadow ease 0.4s, transform ease 0.4s;
  transition: box-shadow ease 0.4s, transform ease 0.4s,
  -webkit-box-shadow ease 0.4s, -webkit-transform ease 0.4s;
}

.card_routines_subcontainer:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-box-shadow: 0 0 2rem 1.5rem rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
}

.card_routines_img_container {
  width: 100%;
  height: 70%;
  border-bottom: thin solid var(--salsafitt_orange);
}

.card_routines_img_img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.card_routines_coach_container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: 30%;
  padding-right: 10px;
  background-color: rgb(44, 44, 44);
}

.card_routines_coach_container h2,
.card_routines_coach_container p {
  margin: 0;
  padding: 0;
}

.card_routines_coach_container h2 {
  font-size: 20px;
  color: var(--salsafitt_orange);
}

.card_routines_coach_container p {
  color: #ffffff;
}