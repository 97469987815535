.menu_item {
  padding: 20px;
  cursor: pointer;
}
.menu_item_img {
  object-fit: cover;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
}


.menu_item_text_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menu_item_text_container h2,
.menu_item_text_container p {
  margin: 0;
  padding: 0;
}

.menu_item_text_container h2 {
  margin-top: 10px;
  font-size: 16px;
}

.menu_item_text_container p {
  color: #ffffff;
}


@media screen and (max-width: 900px) {
  .menu_item_img {
    width: 60px;
    height: 60px;
    border-radius: 30px;
  }
}