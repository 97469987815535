.music {
  width: 100%;
  padding: 100px 0;
  background-color: var(--white_bone);
}

.dance_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  height: auto;

  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 30px;
  padding-top: 50px;
}

.music_container_padd {
  padding-bottom: 100px;
}




.music_lineTop {
  padding: 1rem 0;
  border-top: thin solid var(--salsafitt_orange);
}



.music_btnMore_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 5rem;
  margin-top: 30px;
}

.music_btnMore_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
  width: 15rem;
  height: 100%;
  border-radius: 1rem;
  border: thin solid var(--salsafitt_orange);
  cursor: pointer;
  -webkit-transition: background-color ease 0.4s, color ease 0.4s;
  -o-transition: background-color ease 0.4s, color ease 0.4s;
  transition: background-color ease 0.4s, color ease 0.4s;
  color: var(--salsafitt_orange);
  background-color: transparent;
}

.music_btnMore_btn:hover {
  color: #ffffff;
  background-color: var(--salsafitt_orange);
}

/* ------------------------------ Music Modal ------------------------------ */

