/* Key Frames */

@-webkit-keyframes Circle {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes Circle {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes SalsaFitt {
  75% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  85% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes SalsaFitt {
  75% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  85% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Styles */

.coaches_diagram_container {
  position: relative;
  width: 70rem;
  max-width: 90%;
  height: auto;
  min-height: 40rem;
  margin: 2rem auto;
}

.coaches_diagram_imgCircle_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  z-index: 3;
  width: 10rem;
  height: 10rem;
  border-radius: 10rem;
  border: thin solid var(--salsafitt_orange);
  background-color: #ffffff;
}

.coaches_diagram_animationSalsaFitt {
  -webkit-animation: SalsaFitt 8s ease 1s infinite normal both;
  animation: SalsaFitt 8s ease 1s infinite normal both;
}

.coaches_diagram_imgCircle_img {
  width: 80%;
  height: 80%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top;
  object-position: top;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.15);
}

.coaches_imgCenter_bgDark {
  background-color: var(--salsafitt_grayDark);
}

.coaches_diagram_imgCenter_container {
  width: 8rem;
  height: 8rem;
  top: calc(50% - 4rem);
  left: calc(50% - 4rem);
}

.coaches_diagram_imgCircle_disc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 2rem;
  border: 0.2rem solid #dbdbdb;
}

.coaches_diagram_imgCircle_disc_2 {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-color: #ffffff;
}

.coaches_diagram_imgTopLeft_container {
  top: 2rem;
  left: 1rem;
  -webkit-animation: Circle 4s ease 1s infinite normal both;
  animation: Circle 4s ease 1s infinite normal both;
}

.coaches_diagram_imgTopRight_container {
  top: 4rem;
  right: 3rem;
  -webkit-animation: Circle 6s ease 2s infinite normal both;
  animation: Circle 6s ease 2s infinite normal both;
}

.coaches_diagram_imgBottomLeft_container {
  bottom: 4rem;
  left: 3rem;
  -webkit-animation: Circle 2s ease infinite normal both;
  animation: Circle 2s ease infinite normal both;
}

.coaches_diagram_btnBottomRight_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 12rem;
  height: 12rem;
  bottom: 2rem;
  right: 1rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  padding: 0.5rem;
  -webkit-box-shadow: 0 0 2rem 0.5rem rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 2rem 0.5rem rgba(0, 0, 0, 0.25);
  -webkit-transition: background-color ease 0.4s, color ease 0.4s;
  -o-transition: background-color ease 0.4s, color ease 0.4s;
  -webkit-transition: background-color ease 0.4s, color ease 0.4s,
    -webkit-box-shadow ease 0.4s;
  transition: background-color ease 0.4s, color ease 0.4s,
    -webkit-box-shadow ease 0.4s;
  -o-transition: background-color ease 0.4s, color ease 0.4s,
    box-shadow ease 0.4s;
  -webkit-transition: background-color ease 0.4s, color ease 0.4s,
    -webkit-box-shadow ease 0.4s;
  transition: background-color ease 0.4s, color ease 0.4s,
    -webkit-box-shadow ease 0.4s;
  -o-transition: background-color ease 0.4s, color ease 0.4s,
    box-shadow ease 0.4s;
  -webkit-transition: background-color ease 0.4s, color ease 0.4s,
    -webkit-box-shadow ease 0.4s;
  transition: background-color ease 0.4s, color ease 0.4s,
    -webkit-box-shadow ease 0.4s;
  -o-transition: background-color ease 0.4s, color ease 0.4s,
    box-shadow ease 0.4s;
  transition: background-color ease 0.4s, color ease 0.4s, box-shadow ease 0.4s;
  transition: background-color ease 0.4s, color ease 0.4s, box-shadow ease 0.4s,
    -webkit-box-shadow ease 0.4s;
  transition: background-color ease 0.4s, color ease 0.4s, box-shadow ease 0.4s,
    -webkit-box-shadow ease 0.4s;
  transition: background-color ease 0.4s, color ease 0.4s, box-shadow ease 0.4s,
    -webkit-box-shadow ease 0.4s;
  color: var(--salsafitt_orange);
}

.coaches_diagram_btnBottomRight_container:hover {
  color: #ffffff;
  -webkit-box-shadow: inset 0 0 2rem 0.5rem var(--salsafitt_orange_hover);
  box-shadow: 0 0 2rem 0.5rem rgba(0, 0, 0, 0.25),
    inset 0 0 2rem 0.5rem var(--salsafitt_orange_hover);
  background-color: var(--salsafitt_orange);
}

.coaches_line {
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 45%;
  height: 0.1rem;
  background-color: var(--salsafitt_orange);
}

.coaches_line_TopLeft {
  -webkit-transform: translate(25%, -4.5rem) rotate(30deg);
  -ms-transform: translate(25%, -4.5rem) rotate(30deg);
  transform: translate(25%, -4.5rem) rotate(30deg);
}

.coaches_line_BottomLeft {
  -webkit-transform: translate(25%, 4.5rem) rotate(-30deg);
  -ms-transform: translate(25%, 4.5rem) rotate(-30deg);
  transform: translate(25%, 4.5rem) rotate(-30deg);
}

.coaches_line_TopRight {
  -webkit-transform: translate(100%, -5rem) rotate(-30deg);
  -ms-transform: translate(100%, -5rem) rotate(-30deg);
  transform: translate(100%, -5rem) rotate(-30deg);
}

.coaches_line_BottomRight {
  -webkit-transform: translate(100%, 5rem) rotate(30deg);
  -ms-transform: translate(100%, 5rem) rotate(30deg);
  transform: translate(100%, 5rem) rotate(30deg);
}

/* --------------------------- Coaches: Header --------------------------- */




/* ---------------------------- Coaches: Join Us ---------------------------- */

.coaches_joinUs_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: auto;
  min-height: 30rem;
  padding: 2rem 0;
}

.coaches_joinUs_subcontainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 60%;
  margin: 2rem 0;
}

@media screen and (max-width: 900px) {
  .coaches_joinUs_subcontainer {
    display:block;
    width: 90%;
  }
}





.coaches_joinUs_info_container,
.coaches_joinUs_img_container,
.coaches_joinUs_knowBetter_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 40rem;
}

.coaches_joinUs_knowBetter_container_1 {
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.coaches_joinUs_knowBetter_container_1 h3,
.coaches_joinUs_knowBetter_container_1 p {
  margin: 2rem 1rem;
  padding: 0;
  color: #00000090;
}

.coaches_joinUs_knowBetter_container_1 h3 {
  margin-bottom: 1rem;
  color: var(--salsafitt_orange);
}

.coaches_joinUs_info_container,
.coaches_joinUs_knowBetter_container {
  height: auto;
  flex-direction: column;
}

.coaches_joinUs_info_container h2,
.coaches_joinUs_info_container p,
.coaches_joinUs_knowBetter_container h3,
.coaches_joinUs_knowBetter_container p {
  margin: 2rem 1rem;
  padding: 0;
  color: #00000090;
}

.coaches_joinUs_info_container h2 {
  color: var(--salsafitt_green);
  font-size: 3rem;
}

.coaches_joinUs_img_img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

.coaches_flexStart {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.coaches_joinUs_knowBetter_container h3 {
  margin-bottom: 1rem;
  color: var(--salsafitt_orange);
}

.coaches_form_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.coaches_form_container input,
.coaches_form_container button {
  height: 40px;
  margin-bottom: 5px;
}

.coaches_form_container input,
.coaches_form_container textarea {
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  border: thin solid var(--salsafitt_green);
}

.coaches_form_container textarea {
  margin: 0.5rem 0;
  resize: none;
  font-size: 1.4rem;
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 0.01rem;
}

.coaches_form_container textarea::-webkit-scrollbar {
  width: 1rem;
  background-color: #dfdfdf;
}

.coaches_form_container textarea::-webkit-scrollbar-thumb {
  border: 0.35rem solid #dfdfdf;
  border-radius: 0.7rem;
  background-color: var(--salsafitt_grayDark);
}

.coaches_form_container button {
  margin: 3rem 0 0;
  font-weight: bold;
  border-top-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  border: thin solid var(--salsafitt_green);
  cursor: pointer;
  -webkit-transition: background-color ease 0.4s, color ease 0.4s;
  -o-transition: background-color ease 0.4s, color ease 0.4s;
  transition: background-color ease 0.4s, color ease 0.4s;
  color: #000000;
  background-color: rgba(130, 188, 67, 0.2);
}

.coaches_form_container button:hover {
  color: #ffffff;
  background-color: var(--salsafitt_green);
}
