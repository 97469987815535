.footer {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  background-color: var(--salsafitt_grayDark);
}

.footer_text_lightColor {
  color: rgba(255, 255, 255, 0.5);
}

.footer_paragraph {
  margin: 2rem 0;
  padding: 0;
}

.footer_info_container {
  width: 100%;
  height: 100%;
  padding: 4rem 0;
  margin-bottom: 10rem;
  overflow-x: hidden;
}

.footer_info_subcontainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
  height: auto;
}

.footer_social_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 6rem;
}

.footer_social_logo {
  width: 12rem;
  height: 12rem;
}

.footer_social_icons_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.footer_social_icons_link {
  margin: 0 2rem;
}

.footer_social_icons_link:first-child {
  margin-left: 0;
}

.footer_social_icons_icon {
  color: var(--salsafitt_green);
  cursor: pointer;
  -webkit-transition: color ease 0.6s;
  -o-transition: color ease 0.6s;
  transition: color ease 0.6s;
}

.footer_social_icons_link:hover .footer_social_icons_icon {
  color: var(--salsafitt_orange);
}

.footer_links_container,
.footer_infoHarness_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 80%;
}

.footer_infoHarness_subcontainers {
  width: 100%;
}
.footer_infoHarness_subcontainer {
  max-width: 500px;
}

.footer_links_subcontainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 50%;
  margin: 0 2rem;
}

.footer_links_title,
.footer_infoHarness_title {
  color: var(--salsafitt_orange);
}

.footer_links_link {
  text-decoration: none;
  color: #ffffff;
}

.footer_txt_link {
  cursor: pointer;
}

.footer_links_link h4 {
  margin: 0.5rem 0;
  padding: 0;
  font-size: 1.4rem;
  font-weight: initial;
}

.footer_infoHarness_container {
  margin: 0 6rem;
}

.footer_copyright_container {
  position: absolute;
  left: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 10rem;
}

.footer_divisor {
  position: absolute;
  top: 0;
  left: 7.5%;
  width: 85%;
  height: 0.05rem;
  background-color: rgba(255, 255, 255, 0.1);
}

.footer_getTheApp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 4rem 6rem 0;
}

.footer_getTheApp h3 {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 400;
  color: #ffffff;
}

.footer_getTheApp_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
}

.footer_getTheApp_subcontainer:first-child {
  margin: 0.6rem;
}

.footer_getTheApp_subcontainer:last-child {
  margin: 0.6rem;
}

.footer_getTheApp_subcontainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 2rem;
  border-radius: 0.75rem;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
  -webkit-box-shadow: 0 0 0.1rem 0.2rem rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0.1rem 0.2rem rgba(255, 255, 255, 0.5);
}

.footer_getTheApp_icon_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 30%;
  height: 100%;
}

.footer_getTheApp_info_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 70%;
  height: 100%;
}

.footer_getTheApp_info_text {
  margin: 0;
  padding: 0;
}

.footer_getTheApp_info_topSubcontainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 40%;
}

.footer_getTheApp_info_bottomSubcontainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 60%;
}

/* Media Screens */

@media screen and (max-width: 1000px) {
  .footer_info_container {
    width: calc(100% - 4rem);
  }
  .footer_info_subcontainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .footer_social_container {
    width: 100%;
  }
  .footer_links_container,
  .footer_infoHarness_container {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .footer_links_subcontainer {
    margin: 0 0;
  }
  .footer_getTheApp {
    margin: 0;
  }
  .footer_getTheApp_container {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
