.cart_info_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  min-height: 30rem;
  padding: 100px 0;
  background-image: url("../../assets/bg_dec_rev.png");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 250px;
}

@media screen and (max-width: 900px) {
  .cart_info_container {
    background-size: 100px;
  }
}

.cart_subcontainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 600px;
  margin-right: 100px;
}

@media screen and (max-width: 900px) {
  .cart_subcontainer {
    width: 90%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .cart_info_container {
    padding: 30px 0;
  }
}


.cart_data_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.cart_data_img_container {
  width: 10rem;
  height: 10rem;
  overflow: hidden;
  border-radius: 5px;
}

.cart_data_img_img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.cart_data_title_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 60%;
  height: 100%;
}

.cart_data_title_container h4 {
  text-align: center;
  font-size: 2rem;
  color: var(--salsafitt_orange);
}

.cart_data_p_container {
  padding: 4rem 0 0;
}

.cart_data_p {
  margin: 1.5rem 0;
  color: #00000080;
}

.cart_data_unit_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}

.cart_data_unit_container h4 {
  font-size: 2.4rem;
  color: var(--salsafitt_green);
}

.cart_data_unit_container small {
  font-size: 1.4rem;
  color: #00000080;
}

.cart_quantity_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cart_quantity_quantity,
.cart_quantity_less,
.cart_quantity_plus {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 6rem;
  height: 4rem;
  margin-right: 0.5rem;
  border-top-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  border: thin solid var(--salsafitt_orange);
  -webkit-backdrop-filter: blur(0.25rem);
  backdrop-filter: blur(0.25rem);
}

.cart_quantity_less,
.cart_quantity_plus {
  position: relative;
  cursor: pointer;
  font-size: 2.2rem;
  overflow: hidden;
  -webkit-transition: color ease 0.4s, background-color ease 0.4s;
  -o-transition: color ease 0.4s, background-color ease 0.4s;
  transition: color ease 0.4s, background-color ease 0.4s;
  background-color: rgba(241, 136, 35, 0.2);
}

.cart_quantity_less:hover,
.cart_quantity_plus:hover {
  color: #ffffff;
  background-color: var(--salsafitt_orange);
}

.cart_quantity_save {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
}

.cart_quantity_quantity {
  cursor: default;
  width: 8rem;
  font-size: 1.7rem;
}

.cart_summary_container {
  width: 35rem;
  max-width: 100%;
  height: auto;
  min-height: 30rem;
  margin: 0 1rem;
}

.cart_summary_subcontainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: auto;
}

.cart_summary_subcontainer h5 {
  margin: 1rem 0;
}

.cart_summary_subcontainer p {
  margin: 1rem 0 2rem;
}

.cart_summary_btn {
  width: 100%;
  height: 5rem;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: bold;
  border-top-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  
  -webkit-transition: color ease 0.4s, background-color ease 0.4s;
  -o-transition: color ease 0.4s, background-color ease 0.4s;
  transition: color ease 0.4s, background-color ease 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart_summary_btn:hover {
  color: #ffffff;
}

.cart_summary_btn_color_1 {
  border: thin solid var(--salsafitt_orange);
  background-color: rgba(241, 136, 35, 0.2);
}
.cart_summary_btn_color_1:hover {
  background-color: var(--salsafitt_orange);
}

.cart_summary_btn_color_2 {
  border: thin solid var(--salsafitt_green);
  background-color: rgba(64, 179, 79, 0.2);
}
.cart_summary_btn_color_2:hover {
  background-color: var(--salsafitt_green);
}