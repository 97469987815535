.app_downloadSpace {
  position: relative;
  width: 100%;
  min-height: 100rem;
  background-color: var(--salsafitt_grayDark);
}

.app_coverImage_container {
  width: 100%;
  height: 50rem;
}

.app_coverImage_img,
.app_ilustrationApp_img {
  width: 100%;
  height: 100%;
  -o-object-position: center;
  object-position: center;
}

.app_coverImage_img {
  -o-object-fit: cover;
  object-fit: cover;
}

.app_ilustrator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.app_app_casing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  margin-top: 25rem;
}

.app_ilustrationApp_container {
  width: 35rem;
  height: 45rem;
}

.app_ilustrationApp_img {
  margin: 4rem 0 0;
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-filter: drop-shadow(0 0 0.8rem #000000);
  filter: drop-shadow(0 0 0.8rem #000000);
}

.app_infoApp_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  width: 50%;
  height: 55%;
}

.app_infoApp_subcontainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
}

.app_infoApp_subcontainer h2 {
  margin: 1rem;
  font-size: 4rem;
  color: var(--salsafitt_orange);
}

.app_infoApp_subcontainer p {
  margin: 1rem;
  font-size: 3rem;
  color: #ffffff;
}

.app_getApp_container {
  width: 100%;
  min-height: 20rem;
}

.app_getApp_subcontainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 80%;
  min-height: 20rem;
  margin: 2rem auto 0;
  padding-top: 5rem;
}

/* Media Screen */

@media screen and (max-width: 800px) {
  .app_downloadSpace {
    height: 140rem;
  }
  .app_ilustrator {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .app_app_casing {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .app_infoApp_container {
    width: 80%;
    margin: 10rem 0 0;
  }
  .app_infoApp_subcontainer h2,
  .app_infoApp_subcontainer p {
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  .app_getApp_subcontainer {
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
