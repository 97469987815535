.notfound_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 80vh;
    background-color: var(--salsafitt_grayDark);
    height: auto;
    background-repeat: no-repeat;
    background-position: left top, right bottom;
    background-size: 30rem;
    background-image: url("../../assets/bg_dec.png"), url("../../assets/bg_dec_rev.png");
  }
  
.notfound_container h1{
    color: #fff;
    font-size: 30px;
  }