.harness_principal_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100hv;
  margin: 0 auto;
}

.harness_principal_info_container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 20%;
  height: 100%;
  margin: 3rem 5rem;
}

.harness_principal_info_container h2,
.harness_principal_info_container p,
.harness_principal_info_container h3 {
  margin: 1rem;
  padding: 0;
  text-align: end;
}

.harness_principal_info_container h2 {
  font-size: 5rem;
  color: var(--salsafitt_green);
}

.harness_principal_btn_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15rem;
  height: 5rem;
  margin: 2rem 0;
  border-radius: 0.5rem;
  border-width: medium;
  border-style: solid;
  cursor: pointer;
  text-decoration: none;
  transition: background-color ease 0.4s, color ease 0.4s,
    border-color ease 0.4s;
  border-color: var(--salsafitt_orange);
  background-color: var(--salsafitt_orange);
}

.harness_principal_btn_container:hover {
  border-color: var(--salsafitt_orange_hover);
  color: #ffffff;
  background-color: var(--salsafitt_orange_hover);
}

.harness_principal_btn_container h3 {
  font-size: 1.6rem;
  font-weight: bold;
  color: #ffffff;
}

.harness_principal_img_container {
  width: 30%;
}

.harness_principal_img_img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

/* Harness Info Subcontainer */

.harnessInfo_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.harnessInfo_subcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    padding: 80px 0;
}

@media screen and (max-width: 900px) {
  .harnessInfo_subcontainer {
    display: block;
    padding: 0px 0;
    padding-bottom: 20px;
  }
}

.hi_black {
    color: #ffffff80;
    background-color: var(--salsafitt_grayDark);
}

.hi_black_data_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 35%;
    margin: 3rem;
}

.hi_black_data_title {
    text-align: center;
    font-size: 3.2rem;
    color: var(--salsafitt_orange);
}

.hi_data_p {
    letter-spacing: 0.01rem;
    line-height: 2.5rem;
    text-align: justify;
}

.hi_black_img_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45rem;
    max-width: 100%;
}

.hi_black_img_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 1rem;
    overflow: hidden;
}

.hi_white {
    color: #00000090;
    flex-direction: column;
}

.hi_white_data_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70rem;
    max-width: 80%;
    margin: 3rem;
}

.hi_white_data_title {
    text-align: center;
    font-size: 3.2rem;
    color: var(--salsafitt_green);
}

.hi_white_img_container {
    width: 60%;
}

.hi_white_img_img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

/* Media Screens */

@media screen and (max-width: 700px) {
  .harness_principal_container {
    flex-direction: column;
  }
  .harness_principal_info_container {
    align-items: center;
    width: 90%;
  }
  .harness_principal_info_container h2,
  .harness_principal_info_container p,
  .harness_principal_info_container h3 {
    text-align: center;
  }
  .harness_principal_img_container {
    width: 100%;
  }  
}

@media screen and (max-width: 900px) {
    .hi_black {
        flex-direction: column;
    }
    .hi_black_data_container {
        width: 85%;
    }
    .hi_white_img_container,
    .hi_black_img_container {
        width: 100%;
    }

  .hi_black_img_img {
    width: 90%;
    height: 100%;
}
  
}