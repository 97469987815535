.topbar_titleSpace_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 150px;
  border-bottom: medium solid var(--salsafitt_orange);
  background-color: black;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.topbar_titleSpace_title {
  margin: 0 2rem;
  text-align: center;
  font-size: 25px;
  color: #ffffff;
}

@media screen and (max-width: 900px) {
  .topbar_titleSpace_container {
    height: 100px;
  }
  .topbar_titleSpace_title {
    font-size: 18px;
  }
}